import React from "react";
import {
  Button,
  Container,
  Form,
  FormControl,
  Nav,
  Navbar,
  NavDropdown,
  Offcanvas,
} from "react-bootstrap";
import { NavLink } from "react-router-dom";
import "./MainNavBar.scss";
import { mainMenuLinks as navLinks } from "../utils";
import { BsShieldLockFill } from "react-icons/bs";

const MainNavBar = () => {
  const hasAccessToken = localStorage.getItem("accessToken") !== null;

  return (
    <Navbar bg="custom" className="navbar-light" expand={false}>
      <Container fluid>
        <Navbar.Toggle
          className="custom-toggle"
          aria-controls="offcanvasNavbar"
        >
          <div className="custom-toggle-button">
            <div className="custom-toggle">
              <span className="navbar-toggler-icon"></span>
            </div>
            <div className="navbar-toggle-text">MENU</div>
          </div>
        </Navbar.Toggle>

        {/* <div className="navbar-toggle-icon">
            <div className="navbar-toggle-icon-bar"></div>
            <div className="navbar-toggle-icon-bar"></div>
            <div className="navbar-toggle-icon-bar"></div>
          </div>
           */}

        <Navbar.Offcanvas
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          placement="end"
        >
          <Offcanvas.Header closeButton>
            {/* <Offcanvas.Title id="offcanvasNavbarLabel">Menu</Offcanvas.Title> */}
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Nav className="justify-content-end flex-grow-1 pe-3">
              {navLinks.map(({ text, link }) => {
                return (
                  <NavLink
                    key={text}
                    to={link}
                    className={(navData) =>
                      navData.isActive
                        ? "active-nav custom-link"
                        : "custom-link"
                    }
                  >
                    {text}
                  </NavLink>
                );
              })}
            </Nav>
          </Offcanvas.Body>
        </Navbar.Offcanvas>
      </Container>
    </Navbar>
  );
};

export default MainNavBar;
