import React from "react";
import "./ComingSoon.scss";

import { GiStairsCake, GiBigDiamondRing, GiAmpleDress } from "react-icons/gi";
import { IoIosBowtie } from "react-icons/io";

const ComingSoon = () => {
  return (
    <header className="coming-soon-header">
      <h1>Alice & Atticus' Wedding</h1>
      <div className="d-flex flex-row">
        {/* <GiBigDiamondRing size={70} /> */}
        <GiAmpleDress size={70} />
        <GiStairsCake size={70} />
        <IoIosBowtie size={70} />
      </div>
      <h3>Full website coming soon!</h3>
    </header>
  );
};

export default ComingSoon;
