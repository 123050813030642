import React from "react";

const LinksToOtherPages = ({ showLine = true, pages }) => {
  return (
    <>
      <div className={`rsvp-sections ${showLine ? "" : "d-none"}`}>
        <hr className="mt-4" />
      </div>

      <div>
        <h1 className="mt-4 fancy-font">View Other Pages</h1>
        <div className="d-flex flex-row flex-wrap justify-content-center">
          {pages.map((page) => {
            return (
              <div key={page.text} className="mx-2">
                <a className="defaultLink" href={page.link}>
                  {page.text}
                </a>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default LinksToOtherPages;
