import React, { useEffect, useRef } from "react";
import PageTitle from "../components/PageTitle";
import TopBar from "../components/TopBar";
import Faq from "react-faq-component";
import faqs from "../content/faqs";
import "./Photographs.scss";
import LinksToOtherPages from "../components/LinksToOtherPages";
import { ctaPagesOnFAQ } from "../utils";

import heroPhoto from "../assets/AliceandAtticus-9.jpg";
import { Dropbox } from "dropbox";
import DropboxPortal from "../components/DropboxPortal";

const styles = {
  bgColor: "none",
  titleTextColor: "none",
  rowTitleColor: "none",
  rowContentColor: "none",
  arrowColor: "none",
};

const Photographs = () => {
  const dropboxContainer = useRef(null);

  useEffect(() => {
    var options = {
      // Shared link to Dropbox file
      link: "https://www.dropbox.com/scl/fo/43s75yjxllwy9oxn5l6a3/AM9FweBeZv5OC0Zq3OJ4IuQ?rlkey=he8p8cbwg66mgwjogdgb1p7el&dl=0",
      file: {
        // Sets the zoom mode for embedded files. Defaults to 'best'.
        zoom: "best", // or "fit"
      },
      folder: {
        // Sets the view mode for embedded folders. Defaults to 'list'.
        view: "grid", // or "grid"
        headerSize: "small", // or "small"
      },
    };
    const embed = global.Dropbox.embed(options, dropboxContainer.current);

    return () => {
      global.Dropbox.unmount(embed);
    };
  }, []);
  return (
    <>
      <TopBar />

      <div className="page">
        <div className="wrapper">
          <PageTitle title="Photographs" />
          <div className="dropbox-container" ref={dropboxContainer}></div>
        </div>
      </div>
    </>
  );
};

export default Photographs;
