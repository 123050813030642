const faqs = {
  title: "",
  rows: [
    {
      title: "Timings of the Day",
      content:
        "A timeline of the day can be found at the bottom our homepage but please note that the ceremony will begin promptly at 14:00. We ask that guests arrive no later than 13:30 in order to enjoy a drink and take your seat in plenty of time. ",
    },
    {
      title: "When is the deadline to RSVP?",
      content: "The deadline to RSVP is the 13th May 2023. You are also able to make any changes up until this date, simply revisit the RSVP page, clicking save once finished. ",
    },
    {
      title: "I’ve lost or forgotten my unique code to RSVP",
      content: "Not to worry, just get in touch and we'll be happy to help.",
    },
    {
      title: "Travel, Accommodation & Local Recommendations ",
      content:
        "We realise some of you will be travelling long distances to celebrate with us and it may even be your first time in Yorkshire!  We hope our Travel & Accommodation page provides you with all the information you need but if there’s anything we’ve missed, please feel free to get in touch and we would be more than happy to help. ",
    },
    {
      title: "Parking Arrangements",
      content:
        "We are fortunate that there is plenty of parking at The Devonshire Fell. If by the end of the night you are unable to drive back to your home or accommodation you are more than welcome to leave your car and collect by 11:00 the following day. If it is too far to walk we have provided a list of local car and taxi services on our Travel & Accommodation page. ",
    },
    {
      title: "Venue Accessibility  ",
      content:
        "Despite first impressions The Devonshire Fell does have step-free access. An accessible toilet can also be found on the ground floor just before the main bathrooms. However, please be aware that there are no lifts and all bedrooms are located on either the first or second floor.",
    },
    {
      title: "Dress Code ",
      content:
        "Semi-formal / cocktail. Both the ceremony and reception will be taking place indoors with use of the Fell’s gardens, weather permitting. Fingers crossed the Yorkshire weather will be kind to us but you may wish to bring something warm to watch the fireworks in the evening!",
    },
    {
      title: "Food & Drink",
      content:
        "We hope to keep you well-fed and watered throughout the day with a selection of canapés, afternoon tea wedding breakfast and additional hot and cold food later on.  For those of you less familiar with afternoon tea we recommend a good breakfast and forgoing lunch! We will be serving champagne and a non-alcoholic alternative throughout the day but there will not be an open bar. Any allergies or dietary requirements can be specified when you RSVP.",
    },
    {
      title: "Smoking ",
      content:
        "We kindly ask that any smokers refrain from smoking inside the Fell or its gardens. We are happy for those of you who smoke to do so in the car parking area to be mindful of other guests.",
    },
    {
      title: "Feeling Unwell?",
      content:
        "To protect the health and well-being of all our friends and family we respectfully ask those invited to be cautious regarding illness.  If on the day you feel unwell or have any infective symptoms we kindly ask that you do not attend and we hope to find an alternative way of celebrating with you. ",
    },
    {
      title: "Children & Animals ",
      content: "With regret, we ask that any tiny humans or four-legged friends do not attend on the day and we hope to find an alternative way of celebrating with them.",
    },
    {
      title: "Photographs & Social Media",
      content:
        "Please feel free to take as many photographs as you would like to remember the day. However, please be mindful of our photographers, particularly during the ceremony. With regards to posting on social media we'd appreciate you checking before posting any photos of us or our immediate families. ",
    },
    {
      title: "Can I attend the ceremony as an evening guest?",
      content:
        "Unfortunately, due to venue restrictions, there is no additional seated or standing room during the ceremony. However, you are more than welcome and we would love for you to join us afterwards for drinks and photographs should you wish. ",
    },
    {
      title: "Cards & Gifts",
      content:
        "We hope you know that your presence at our wedding is the most valuable gift to us. However, if you would like to give a gift, our gift registry page has a list of items we would appreciate as replacements for well-used belongings or things we have been interested in. Alternatively, any contribution towards our savings for a honeymoon or future flat would be greatly appreciated.",
    },
  ],
};
export default faqs;
