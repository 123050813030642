import React, { useEffect, useMemo, useState } from "react";
import MapContainer from "../components/GoogleMaps";
import PageTitle from "../components/PageTitle";
import TopBar from "../components/TopBar";
import accommodation from "../content/accommodation";
import "./TravelAccom.scss";
import { AiFillCar } from "react-icons/ai";
import { MdNordicWalking } from "react-icons/md";
import { GiFishingPole, GiCoffeeCup, GiFlowerPot } from "react-icons/gi";
import { WiTrain } from "react-icons/wi";
import { TbMassage } from "react-icons/tb";

import taxis from "../content/taxis";
import { Button, Modal } from "react-bootstrap";
import DefaultLink from "../components/DefaultLink";
import { ctaPagesOnTravel } from "../utils";
import LinksToOtherPages from "../components/LinksToOtherPages";

const TravelAccom = () => {
  const iconSize = 25;
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const accommodationList = useMemo(() => {
    const uniquePlaces = [...new Set(accommodation.map((item) => item.location))];
    const accommodationByPlace = uniquePlaces.reduce((acc, curr) => ((acc[curr] = ""), acc), {});

    uniquePlaces.forEach((place) => {
      const items = accommodation.filter(({ location }) => location === place);
      accommodationByPlace[place] = items;
    });
    return accommodationByPlace;
  }, []);

  return (
    <>
      <TopBar />
      <div className="page">
        <div className="wrapper">
          <PageTitle title="Travel & Accommodation" />
          <div className="travel-accommodation-sections-container mb-5">
            <section>
              <h2>Getting Here</h2>
              <div className="d-flex flex-row flex-wrap" style={{ maxWidth: "800px", margin: "0 auto", textAlign: "left" }}>
                <div className="flex-grow-1 d-flex justify-content-center">
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d998.7229245430145!2d-1.9541312253257372!3d54.044045214674!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x487bf64651be87f7%3A0x6362e54179e39f94!2sThe%20Devonshire%20Fell!5e0!3m2!1sen!2suk!4v1650574595041!5m2!1sen!2suk"
                    style={{ border: 0, padding: "10px", maxWidth: "600px", width: "100%" }}
                    height="300"
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
                <div className="p-2 d-flex align-items-center">
                  <div>
                    <b>THE DEVONSHIRE FELL</b>
                    <br />
                    Burnsall Village
                    <br />
                    Skipton
                    <br />
                    Yorkshire Dales National Park
                    <br />
                    North Yorkshire
                    <br />
                    BD23 6BT
                    <br />
                    <br />
                  </div>
                </div>
              </div>
              <div className="mt-2">
                Travelling by train? The nearest stations are <DefaultLink link="https://goo.gl/maps/pCdm356vs7hW3d9F8">Skipton</DefaultLink>
                &nbsp;&&nbsp;
                <DefaultLink link="https://goo.gl/maps/rGtpket4bi9w66dY6">Ilkley</DefaultLink>
              </div>

              <br />
              <div className="heart-separator"></div>
            </section>

            <section>
              <h2>Places to Stay</h2>
              <p className="text-center" style={{ maxWidth: "650px", margin: "0 auto", padding: "10px" }}>
                Below is a curated list of local accommodation and their contact information. <br />
                <br /> Guests staying at either The Devonshire Fell or The Devonshire Arms will receive a Bolton Abbey Estate parking pass and complimentary access to the Devonshire Spa.
              </p>

              <Button className="mt-2" variant="custom" type="submit" size="md" onClick={handleShow}>
                View Local Accommodation
              </Button>
              <Modal dialogClassName="modal-custom" size="lg" show={show} onHide={handleClose}>
                <Modal.Header style={{ border: "none" }} closeButton></Modal.Header>
                <div className="mt-4" style={{ maxWidth: "1000px", margin: "0 auto" }}>
                  {Object.entries(accommodationList).map((location, index) => {
                    return (
                      <>
                        <h4>{location[0]}</h4>
                        <span>
                          <AiFillCar size={20} />
                          &nbsp;{location[1][0].distance} mins
                        </span>
                        <div className="timeline-container my-3">
                          {location[1].map(({ name, rooms, phone, website }) => (
                            <div className="event accommodation-item">
                              <h6>{name}</h6>
                              <div className="event-details">
                                <div>
                                  {rooms} | <DefaultLink link={website}>Website</DefaultLink> | <DefaultLink link={`tel:${phone}`}>{phone}</DefaultLink>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                        {index + 1 < Object.entries(accommodationList).length && <hr />}
                      </>
                    );
                  })}
                </div>
              </Modal>
              <div className="heart-separator mt-4"></div>
            </section>
            <section>
              <h2>Parking & Taxis</h2>
              <p className="text-center" style={{ maxWidth: "700px", margin: "0 auto", padding: "10px" }}>
                For those staying at the Fell or driving to us on the day of the wedding there is plenty of parking on-site. If by the end of the night you are unable to drive back to your home or
                accommodation you are welcome to leave your car and collect by 11:00 on the 14th September. <br />
                <br />A car service is available to book for those staying at The Devonshire Arms, or alternatively, please see below for local taxi companies and private vehicle hire:
              </p>
              <div className="timeline-container mt-3">
                {taxis.map(({ name, phone, website }) => {
                  return (
                    <div className="event accommodation-item">
                      <h6>{name}</h6>
                      <div className="event-details">
                        <div>
                          <DefaultLink link={website}>Website</DefaultLink> | <DefaultLink link={`tel:${phone}`}>{phone}</DefaultLink>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </section>
            <section>
              <div className="heart-separator mt-4"></div>
              <h2>Things to Do</h2>
              <p className="text-center" style={{ maxWidth: "650px", margin: "0 auto", padding: "10px" }}>
                If you are planning a longer trip and looking for things to do in the local area, below are some suggestions.
              </p>
              <div style={{ maxWidth: "800px", margin: "0 auto", textAlign: "left" }}>
                <div className="timeline-container">
                  <div className="event todo-item">
                    <MdNordicWalking size={iconSize} />
                    &nbsp; Explore the&nbsp;<DefaultLink link="https://boltonabbey.com/">Bolton Abbey Estate</DefaultLink>
                  </div>
                  <div className="event todo-item">
                    <GiFishingPole size={iconSize} />
                    &nbsp;Go&nbsp;<DefaultLink link="https://boltonabbey.com/the-estate/fishing/">Fishing on the River Wharfe</DefaultLink>
                  </div>
                  <div className="event todo-item">
                    <TbMassage size={iconSize} />
                    &nbsp;Relax at&nbsp;<DefaultLink link="https://devonshirehotels.co.uk/devonshire-arms-hotel-spa/spa/">The Devonshire Spa</DefaultLink>
                  </div>
                  <div className="event todo-item">
                    <WiTrain size={iconSize} />
                    &nbsp;Ride the&nbsp;<DefaultLink link="https://www.embsayboltonabbeyrailway.org.uk/">Bolton Abbey Steam Railway</DefaultLink>
                  </div>
                  <div className="event todo-item">
                    <GiCoffeeCup size={iconSize} />
                    &nbsp; Take a trip to Betty’s Café Tea Rooms in either&nbsp;<DefaultLink link="https://www.bettys.co.uk/cafe-tea-rooms/our-locations/bettys-ilkley">Ilkley</DefaultLink>
                    &nbsp;or&nbsp;
                    <DefaultLink link="https://www.bettys.co.uk/cafe-tea-rooms/our-locations/bettys-harrogate">Harrogate</DefaultLink>
                  </div>
                  <div className="event todo-item">
                    <GiFlowerPot size={iconSize} />
                    &nbsp;Visit&nbsp;<DefaultLink link="https://www.rhs.org.uk/gardens/harlow-carr">RHS Garden Harlow Carr</DefaultLink>
                  </div>
                </div>
              </div>
            </section>
          </div>

          <LinksToOtherPages pages={ctaPagesOnTravel} />
        </div>
      </div>
    </>
  );
};

export default TravelAccom;
