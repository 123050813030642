import React from "react";
import "./PageTitle.scss";

const PageTitle = ({ title }) => {
  return (
    <div className="page-title">
      <h1>
        <span>{title}</span>
      </h1>
    </div>
  );
};

export default PageTitle;
