import React from "react";
import PageTitle from "../components/PageTitle";
import TopBar from "../components/TopBar";
import Faq from "react-faq-component";
import faqs from "../content/faqs";
import "./FAQ.scss";
import LinksToOtherPages from "../components/LinksToOtherPages";
import { ctaPagesOnFAQ } from "../utils";

const styles = {
  bgColor: "none",
  titleTextColor: "none",
  rowTitleColor: "none",
  rowContentColor: "none",
  arrowColor: "none",
};

const FAQ = () => {
  return (
    <>
      <TopBar />
      <div className="page">
        <div className="wrapper">
          <PageTitle title="Frequently Asked Questions" />
          <div className="rsvp-sections mb-5">
            <div className="faq-container">
              <Faq data={faqs} styles={styles} />
            </div>
          </div>
          <LinksToOtherPages showLine={false} pages={ctaPagesOnFAQ} />
        </div>
      </div>
    </>
  );
};

export default FAQ;
