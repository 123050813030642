import React from "react";
import MainNavBar from "./MainNavBar";
import "./TopBar.scss";
import logo from "../assets/our-names-final-optimised.jpg";

const TopBar = () => {
  return (
    <>
      <div className="top-bar">
        <div className="main-heading">
          <div>
            <a href="/home" className="heading-logo-text">
              {/* <img className="heading-logo" src={logo} /> */}
              <h1 style={{ fontSize: "60px" }} className="fancy-font">
                Alice & Atticus
              </h1>
            </a>
            <span style={{ opacity: "0.7", fontSize: "18px" }}>13th September 2023</span>
          </div>
        </div>
        <MainNavBar />
      </div>
    </>
  );
};

export default TopBar;
