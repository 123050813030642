import React, { useState } from "react";
import TopBar from "../components/TopBar";
import "./HomePage.scss";
import CountDown from "../components/CountDown";
import {
  GiLinkedRings,
  GiPartyPopper,
  GiStairsCake,
  GiKnifeFork,
  GiSparkles,
  GiChampagneCork,
  GiMeal,
  GiNightSleep,
} from "react-icons/gi";
import { BiCamera } from "react-icons/bi";
import { AiOutlineClockCircle } from "react-icons/ai";
import ReactPlayer from "react-player";
import HomePageVideo from "../assets/Final_yt_compressed.mp4";
import LinksToOtherPages from "../components/LinksToOtherPages";
import { ctaPagesOnHome } from "../utils";

const HomePage = ({}) => {
  const events = [
    { icon: AiOutlineClockCircle, time: "13:30", title: "Arrival" },
    { icon: GiLinkedRings, time: "14:00", title: "Ceremony" },
    { icon: GiChampagneCork, time: "14:30", title: "Drinks Reception" },
    { icon: BiCamera, time: "15:00", title: "Photographs" },
    { icon: GiMeal, time: "16:30", title: "Wedding Breakfast" },
    { icon: GiPartyPopper, time: "19:00", title: "Evening Celebrations" },
    { icon: GiStairsCake, time: "20:00", title: "Cake Cutting" },
    { icon: GiKnifeFork, time: "21:00", title: "Evening Food" },
    { icon: GiSparkles, time: "22:00", title: "Sparklers" },
    { icon: GiNightSleep, time: "00:00", title: "End" },
  ];
  return (
    <div>
      <TopBar />
      <div className="wrapper">
        <div className="main-image-container">
          {/* <img
            alt="Alice and Atticus crossing stepping stones at bolton abbey."
            className="hero-image"
            src={imageOfUs}
          /> */}
          <ReactPlayer
            url={HomePageVideo}
            playing={true}
            loop={true}
            volume={0}
            width="100%"
            height="100%"
          />
        </div>
        {/* <div className="heart-separator"></div> */}
        <section className="countdown-section section odd">
          <span className="fancy-font display-5">Married for</span>
          <CountDown />
        </section>
        <div className="heart-separator"></div>
        <section className="section even">
          <p className="poem-caption">
            We realise a Wednesday wedding is a little unusual, but this Old
            English folk rhyme would suggest the 10 year anniversary of us
            meeting has fallen well!
          </p>
          <p className="poem fancy-font">
            <span className="quote">&#8216;</span>
            Marry on Monday for wealth,
            <br />
            Tuesday for health,
            <br />
            Wednesday the best day of all,
            <br />
            Thursday for losses,
            <br />
            Friday for crosses,
            <br />
            and Saturday no luck at all
            <span className="quote">&#8217;</span>
          </p>
        </section>
        <div className="heart-separator"></div>
        <section className="section odd">
          <h2>Order of the Day</h2>
          <div className="timeline-container">
            {events.map((event) => (
              <div className="event" key={event.time}>
                <div className="event-marker">
                  <event.icon size={40} />
                </div>
                <div className="event-details">
                  <div className="event-time">{event.time}</div>
                  <div className="event-title">{event.title}</div>
                </div>
              </div>
            ))}
          </div>
        </section>

        <LinksToOtherPages pages={ctaPagesOnHome} />
      </div>
    </div>
  );
};

export default HomePage;
