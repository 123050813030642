import React, { useEffect, useRef, useState } from "react";
import { blankTimePlaceholder, getTimeUntil } from "../utils";
import "./CountDown.scss";

const CountDown = () => {
  const deadline = new Date(2023, 8, 13, 14, 0, 0, 0);
  const timer = useRef(null);
  const [countDown, setCountdown] = useState(getTimeUntil(deadline));

  useEffect(() => {
    timer.current = setInterval(() => {
      setCountdown(getTimeUntil(deadline));
    }, 1000);

    return () => {
      clearInterval(timer.current);
    };
  }, []);

  return (
    <>
      {countDown && (
        <div className="countdown">
          {countDown.map((item) => {
            return (
              item.num !== null && (
                <div key={item.label}>
                  <span className="countdown-num">{item.num}</span>
                  <span className="countdown-label">{item.label}</span>
                </div>
              )
            );
          })}
        </div>
      )}
    </>
  );
};

export default CountDown;
