import "bootstrap/dist/css/bootstrap.min.css";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.scss";
import ComingSoon from "./pages/ComingSoon";
import FAQ from "./pages/FAQ";
import HomePage from "./pages/HomePage";
import TravelAccom from "./pages/TravelAccom";
import Photographs from "./pages/Photographs";

function App() {
  const splashPage = false;
  const deadline = new Date("May 13, 2023 00:00:00");

  const now = new Date();

  return (
    <div className="App">
      <Router>
        <Routes>
          <Route path="home" element={<HomePage />} />
          <Route path="travel-accommodation" element={<TravelAccom />} />
          <Route path="faq" element={<FAQ />} />
          <Route path="photographs" element={<Photographs />} />

          <Route
            path="/*"
            element={splashPage ? <ComingSoon /> : <HomePage />}
          />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
