import { useEffect } from "react";
import { createPortal } from "react-dom";

const DropboxPortal = ({ link, children }) => {
  const mount = document.getElementById("dropbox-root");
  const el = document.createElement("div");

  useEffect(() => {
    mount.appendChild(el);
    global.Dropbox.embed({ link }, el);

    return () => {
      mount.removeChild(el);
    };
  }, [el, link, mount]);

  return createPortal(children, el);
};

export default DropboxPortal;
