const taxis = [
  {
    name: "Dales Cab Company",
    phone: "07515 868788",
    website: "http://www.dalescabcompany.co.uk/",
  },
  {
    name: "Dalesman Central Taxis",
    phone: "01756 794444",
    website: "http://www.dalesmancentraltaxis.com/",
  },
  {
    name: "Harrogate Chauffeurs",
    phone: "07966 030030",
    website: "https://www.harrogatechauffeurs.com/",
  },
  {
    name: "Silsden & Steeton Taxis",
    phone: "01535 656566",
    website: "http://www.silsdentaxis.co.uk/",
  },
  {
    name: "Skipton & Craven Taxis",
    phone: "01756 701122",
    website: "https://skiptontaxis.com/",
  },
  {
    name: "Steels Coaches of Addingham",
    phone: "01943 830206",
    website: "http://www.steelscoaches.co.uk/privatehire.html",
  },
];

export default taxis;
