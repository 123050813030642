import React from "react";

const DefaultLink = ({ link = "#", newTab = true, children }) => {
  return (
    <a className="defaultLink" href={link} target={newTab ? "_blank" : "_self"} rel="noopener noreferrer">
      {children}
    </a>
  );
};

export default DefaultLink;
