import * as dateFns from "date-fns";

export function formReducer(prevState = null, state) {
  const { value = null, key = null, action = null } = state;
  if (value === null && key === null && action === null) {
    return prevState;
  }

  if (action === "initialise") {
    return value;
  }

  const updatedElement = { ...prevState[key] };
  updatedElement.value = value;

  return { ...prevState, [key]: updatedElement };
}

export function getTimeUntil(futureDate) {
  const duration = dateFns.intervalToDuration({
    start: new Date(),
    end: new Date(futureDate),
  });

  const time = [
    { num: null, label: "years" },
    { num: null, label: "months" },
    { num: null, label: "days" },
    { num: null, label: "hours" },
    { num: null, label: "minutes" },
    { num: null, label: "seconds" },
  ];

  time.forEach((element, index, myArr) => {
    const num = duration[element.label];

    const prevNum =
      myArr[index - 1] !== undefined ? myArr[index - 1].num : null;

    myArr[index].num = prevNum !== null || num > 0 ? num : null;
    myArr[index].label = num > 1 ? element.label : element.label.slice(0, -1);
  });

  return time;
}

// .reduce((a, v) => ({ ...a, [v[0]]: v[1] }), {});
export const capitalise = (s) => s && s[0].toUpperCase() + s.slice(1);

const homePage = { text: "Home", link: "/home" };
const travelPage = {
  text: "Travel & Accommodation",
  link: "/travel-accommodation",
};
const faqPage = { text: "FAQs", link: "/faq" };
const photographsPage = { text: "Photographs", link: "/photographs" };

export const mainMenuLinks = [homePage, travelPage, photographsPage, faqPage];
export const ctaPagesOnHome = [travelPage, photographsPage, faqPage];
export const ctaPagesOnTravel = [photographsPage, faqPage];
export const ctaPagesOnFAQ = [travelPage, photographsPage];

export const scrollToBottom = () => {
  window.scrollTo({
    left: 0,
    top: document.body.scrollHeight,
    behavior: "smooth",
  });
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function countOccurrences(item, items = []) {
  if (!Array.isArray(items)) {
    return 0;
  }
  let count = 0;
  for (let i = 0; i < items.length; i++) {
    if (item === items[i]) {
      count++;
    }
  }
  return count;
}

export function makeNameFromLabel(label) {
  return label.toLowerCase().split(" ").join("-");
}
