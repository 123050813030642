const accommodation = [
  {
    name: "The Devonshire Fell Hotel",
    rooms: "16 rooms",
    phone: "01756 729000",
    website: "https://devonshirehotels.co.uk/devonshire-fell-burnsall/",
    location: "Burnsall",
    distance: 0,
  },
  {
    name: "Red Lion Pub and Manor House",
    rooms: "25 rooms & 5 cottages",
    phone: "01756 720204",
    website: "https://redlion.co.uk/",
    location: "Burnsall",
    distance: 0,
  },
  {
    name: "The Old Post Office",
    rooms: "4 rooms, sleeps 4x2",
    phone: "07875 534133",
    website: "https://www.booking.com/hotel/gb/the-old-post-office-burnsall.en-gb.html",
    location: "Burnsall",
    distance: 0,
  },
  {
    name: "The Old Cobblers Holiday Cottage",
    rooms: "2 rooms, sleeps 2x2",
    phone: "01756 720320",
    website: "https://www.booking.com/hotel/gb/the-old-cobblers.en-gb.html",
    location: "Burnsall",
    distance: 0,
  },
  {
    name: "Wharfe View Farm Bed & Breakfast",
    rooms: "4 rooms, sleeps x10",
    phone: "01756 720643",
    website: "http://burnsall.net/bed-breakfast/",
    location: "Burnsall",
    distance: 0,
  },
  {
    name: "Walker Fold Holiday Cottage",
    rooms: "3 rooms, sleeps 3x2",
    phone: "01756 720658",
    website: "http://walkerfoldholidaycottage.co.uk/",
    location: "Hartington",
    distance: 3,
  },
  {
    name: "The Devonshire Arms Hotel & Spa",
    rooms: "40 rooms",
    phone: "01756 718111",
    website: "https://devonshirehotels.co.uk/devonshire-arms-hotel-spa/",
    location: "Bolton Abbey",
    distance: 12,
  },
  {
    name: "Devonshire Arms Cottages",
    rooms: "3 cottages, 2 sleep x6 & 1 sleeps x8",
    phone: "01756 718111",
    website: "http://devonshirearmscottages.co.uk",
    location: "Bolton Abbey",
    distance: 12,
  },
  {
    name: "Craven Cottage",
    rooms: "3 rooms, sleeps 3x2",
    phone: "07711 530156",
    website: "https://www.cravencottageappletreewick.com/",
    location: "Appletreewick",
    distance: 5,
  },
  {
    name: "The New Inn",
    rooms: "6 rooms",
    phone: "01756 720252",
    website: "https://appletreewick.pub/",
    location: "Appletreewick",
    distance: 5,
  },
  {
    name: "Linton Laithe Bed & Breakfast",
    rooms: "5 rooms",
    phone: "01756 753209",
    website: "https://lintonlaithe.co.uk/",
    location: "Linton",
    distance: 8,
  },
  {
    name: "The Fountaine Inn",
    rooms: "5 rooms",
    phone: "01756 752210",
    website: "https://fountaineinnatlinton.co.uk/",
    location: "Linton",
    distance: 8,
  },
  {
    name: "Barden Bunk Barn",
    rooms: "4 rooms, 2 sleep x4 & 2 sleep x6",
    phone: "01756 720616",
    website: "https://www.thebarnatbarden.com/",
    location: "Barden",
    distance: 8,
  },
  {
    name: "Howgill Lodge",
    rooms: "4 lodges, each sleep x6",
    phone: "01756 720665",
    website: "https://www.howgill-lodge.co.uk/",
    location: "Barden",
    distance: 8,
  },
  {
    name: "Grassington Lodge",
    rooms: "12 rooms",
    phone: "01756 752518",
    website: "https://www.grassingtonlodge.co.uk/",
    location: "Grassington",
    distance: 11,
  },
  {
    name: "Ashfield House",
    rooms: "8 rooms",
    phone: "01756 752284",
    website: "https://www.ashfieldhouse.co.uk/",
    location: "Grassington",
    distance: 11,
  },
  {
    name: "Grassington House",
    rooms: "9 rooms",
    phone: "01756 752406",
    website: "https://www.grassingtonhouse.co.uk/",
    location: "Grassington",
    distance: 11,
  },
  {
    name: "Bridge End Farm Cottage",
    rooms: "3 rooms, sleeps 3x2",
    phone: "01756 752463",
    website: "https://www.bridge-end-farm.co.uk/",
    location: "Grassington",
    distance: 11,
  },
  {
    name: "The Rendezvous Hotel",
    rooms: "80 rooms",
    phone: "01756 700100",
    website: "https://rendezvous-skipton.co.uk/",
    location: "Skipton",
    distance: 20,
  },
  {
    name: "Travelodge",
    rooms: "32 rooms",
    phone: "0871 984617",
    website: "https://www.travelodge.co.uk/hotels/176/Skipton-hotel",
    location: "Skipton",
    distance: 20,
  },
  {
    name: "Herriots Hotel",
    rooms: "25 rooms",
    phone: "01756 792781",
    website: "https://www.herriotsforleisure.co.uk/rooms.html",
    location: "Skipton",
    distance: 20,
  },
  {
    name: "Rooms at Napier’s",
    rooms: "14 rooms & 1 cottage",
    phone: "01756 799688",
    website: "https://bedandbreakfastskipton.co.uk/",
    location: "Skipton",
    distance: 20,
  },
  {
    name: "Craiglands Hotel & Spa",
    rooms: "63 rooms",
    phone: "01943 430002",
    website: "https://www.craiglands.co.uk/",
    location: "Ilkley",
    distance: 22,
  },
  {
    name: "The Wheatley Arms",
    rooms: "12 rooms",
    phone: "01943 816496",
    website: "https://wheatleyarms.co.uk/",
    location: "Ilkley",
    distance: 22,
  },
  {
    name: "The Crescent Inn",
    rooms: "14 rooms",
    phone: "01943 811250",
    website: "https://thecrescentinn.co.uk/",
    location: "Ilkley",
    distance: 22,
  },
];

export default accommodation;
